@use "../../../../../scss/break-points";
@use "../../../../../scss/colors";
@use '../../../../../scss/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;


  &.light {
    color: colors.$textColorLight;

  }

  &.dark {
    color: #fff;
    opacity: 0.9;
  }


  .icon {
    cursor: pointer;
  }

  .brandContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;

    .brandName {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 37px;
    }
  }

  .menuContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    @media (max-width:break-points.$md) {
      gap: 10px;
    }
    @media (max-width:360px) {
      gap: 4px;
    }
    .HistoryIconsmall {
      @media (max-width:break-points.$md) {
        display: none;
      }
    }
  }

  .menuShareContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    .HistoryIconsmall {
      @media (max-width:break-points.$md) {
        display: none;
      }
    }
  }

  .pageTitle {
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-left: 37px;

    @media (max-width:break-points.$sm) {
      width: 100%;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .logoutButton {
    padding: 5px 6px;
    border-radius: 10px;
    background-color: transparent;


    .btnContent {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;

      &.light {
        color: colors.$textColorLight;

        svg {
          & path {
            stroke: colors.$textColorLight;
          }
        }
      }

      &.dark {
        color: colors.$white;

        svg {
          & path {
            stroke: colors.$white;
          }
        }
      }

      .text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        @media (max-width: break-points.$sm) {
          font-size: 13px;
          line-height: 18px;
        }
      }

      .icon {
        width: 22px;
        height: 22px;

        @media (max-width: break-points.$sm) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.shareChatbtn {
  @media (min-width: break-points.$sm) {
    border: 1.5px solid colors.$primaryColor !important;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: variables.$pagePadding;
  }

  @media (max-width: break-points.$sm) {
    background: transparent;
  }
}

.shareChatTryBtn {
  background-color: transparent;
  color: colors.$primaryColor !important;
  padding: 5px 13px;

  @media (max-width: break-points.$sm) {
    background-color: transparent;
    color: colors.$primaryColor !important;
    padding:0px;
  }
}


// team Nav

.btnContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: colors.$darkModeText;

  svg {
    & path {
      stroke: colors.$darkModeText;
    }
  }

  .text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .icon {
    width: 22px;
    height: 22px;
  }
}

.settingIcon{

  svg {
    & path {
      fill: none !important;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .account {
    display: block; 
  }
}
@media (max-width: 576px) {
  .account {
    display: none; 
  }
}
.placeholder {
  flex: 1; 
}