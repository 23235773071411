@use "../../../scss/variables";
@use "../../../scss/z-indices";
@use "../../../scss/break-points";
@use "../../../scss/colors";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.navbar {
  width: 100%;
  height: auto;
  position: fixed;
  top: 10;
  right: 0;
  z-index: z-indices.$navbar;
  transition: width variables.$sb-transition ease;
  padding: 0 variables.$pagePadding;

  &.shareChatLight{
    background-color: #f0f4f9 !important;
  }
  &.light{
    background-color: rgba(255, 255, 255);
  }
  &.dark{
  background-color: #181819;
  }
  &.teamlight{
    background: #F8FBFF;
  }
 @media (max-width: break-points.$sm) {
    padding: 0px 23px;
  }
  @media (max-width: 360px) {
    padding: 0px 19px;
  }
  &.isSidebarOpen {
    padding: 0 variables.$pagePadding 0 0;
    transition: width variables.$sb-transition ease;

    @media (max-width: break-points.$md) {
      width: 100%;
    }
  }

  &.reSizeNavBar{
    position: relative;
    padding: 0px;
  }
 
  .buttonsContainer {
    position: absolute;
    left: variables.$pagePadding;
    top: 50%;
    transform: translateY(-50%);
    z-index: z-indices.$navbar + 1;
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: break-points.$sm) {
      left: 35px;
    }
    @media (max-width: 360px) {
      left: 23px;
    }

    &.teamButtonContainer{
      @media (max-width: break-points.$md) {
        left: 20px
      }
    }
    .openSidebarBtn {
      border: none;
      margin: 0;
      padding: 0;
      background-color: transparent;
      @media (max-width:break-points.$md) {
        margin-bottom: -2px;
      }

      &.light {
        svg {
          & path {
            fill: colors.$burgerIconColor;
          }
        }
      }

      &.dark {
        svg {
          & path {
            fill: #fff;
          }
        }
      }

      &.teamButtonContainer{
        svg {
          & path {
            fill: #fff;
          }
        }
      }
    }

    .logoButton {
      background-color: transparent;
      padding: 0;
      margin-top: -3.5px;
      @media (max-width:break-points.$md) {
        margin-top:-1.5px
      }

      .logoText {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &.light {
          color: colors.$textColorLight;
        }

        &.dark {
          color: colors.$white;
        }
      }
    }
  }

  .lgNav {
    display: block;
    height: variables.$navbar;
  }

  .mdNav {
    height: variables.$md-navbar;
  }
}
