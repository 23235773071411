@use "../../../../../scss/colors";
@use "../../../../../scss/variables";
@use "../../../../../scss/break-points";

.container {
  position: relative;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // @media (max-width: break-points.$sm) {
  //   margin-left: 30px;
  // }
  @media (max-width: break-points.$md) {
    margin-left: 14%;
  }
  @media (max-width: 360px) {
    margin-left: 10%;
  }
  &.shareChat{
    margin-left: 0px;
    @media (max-width: 340px) {
      margin-left: 0px;
    }
  }

  .modelButton {
    border: none;
    padding: 6px 7px 6px 16px;
    margin: 0;
    border-radius: 300px;

    @media (max-width: break-points.$md) {
      padding: 6px 8px 6px 11px;
    }

    @media (max-width: break-points.$sm) {
      padding: 4px 8px 4px 11px;
    }

    &.light {
      color: colors.$textColorLightMode;
      background: colors.$darkModeText;

      /* shadow */
      box-shadow: 0px 206px 58px 0px rgba(120, 120, 120, 0.00), 0px 132px 53px 0px rgba(120, 120, 120, 0.01), 0px 74px 45px 0px rgba(120, 120, 120, 0.05), 0px 33px 33px 0px rgba(120, 120, 120, 0.09), 0px 8px 18px 0px rgba(120, 120, 120, 0.10);

      svg {
        & path {
          fill: colors.$textColorLightMode;
        }
      }
    }

    &.dark {
      background: colors.$darkModeSetting;
      color: colors.$darkModeText;

      svg {
        & path {
          fill: colors.$darkModeText;
        }
      }

      &.shareChat{
        @media (max-width: break-points.$sm) {
          padding: 4px 8px 4px 8px;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .modelIconContainer {
        width: 24px;
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media (max-width: break-points.$md) {
          width: 20px;
          height: 20px;
        }
      }

      .model {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        margin-left: 13px;

        @media (max-width: break-points.$sm) {
          font-size: 10px;
          margin-left: 8px;
        }
      }
    }
  }
}

.creditsContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: variables.$pagePadding;
}

.settingIcon{
  display: flex;
  align-items: center;
  cursor: pointer;
   margin-right: 25px;

}

.LogoContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: variables.$pagePadding;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareChatbtn {
  border: 1.5px solid colors.$primaryColor !important;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: variables.$pagePadding;
}

.shareChatTryBtn {
  border: 1 px solid colors.$primaryColor !important;
  color: colors.$primaryColor !important;
}

.multipleaiModel {
  margin-left: 0.2em;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
  }
}
.image{
  background-color: white;
  border-radius: 50%;
  padding: 2px;
 
}
